/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/prismjs@1.19.0/prism.min.js
 * - /npm/prismjs@1.19.0/components/prism-markup.min.js
 * - /npm/prismjs@1.19.0/components/prism-clike.min.js
 * - /npm/prismjs@1.19.0/components/prism-javascript.min.js
 * - /npm/prismjs@1.19.0/components/prism-diff.min.js
 * - /npm/prismjs@1.19.0/components/prism-yaml.min.js
 * - /npm/prismjs@1.19.0/plugins/line-numbers/prism-line-numbers.min.js
 * - /npm/prismjs@1.19.0/plugins/diff-highlight/prism-diff-highlight.min.js
 * - /npm/prismjs@1.19.0/plugins/toolbar/prism-toolbar.min.js
 * - /npm/prismjs@1.19.0/plugins/copy-to-clipboard/prism-copy-to-clipboard.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
